
import { defineComponent, reactive, toRefs, onMounted, watch, inject, ref, computed } from 'vue';
import { GridContent } from '@/components';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'AccountSettings',
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const state = reactive<{
      selectedKeys: string[];
    }>({
      selectedKeys: [],
    });

    const updateCurrentSelectMenu = () => {
      const allMatched = route.matched.concat();
      state.selectedKeys = allMatched.map(item => item.path);
    };

    onMounted(() => {
      updateCurrentSelectMenu();

      watch(
        () => route.fullPath,
        () => {
          updateCurrentSelectMenu();
        },
      );
    });
    const isMobile = inject('isMobile', ref(false));
    return {
      t,
      isMobile,
      mode: computed(() => (isMobile.value ? 'horizontal' : 'vertical')),
      ...toRefs(state),
    };
  },
  components: { GridContent },
});
